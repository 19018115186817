<template>
  <v-container class="container">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>

        <v-card-title>Create or Select your organization.</v-card-title>
        <v-card-text v-if="event && event.org">
          <v-alert type="info">This event is already linked to the organization <strong>{{event.org.name}}</strong>.</v-alert>
          <v-btn outlined color="primary" @click="unlinkFromOrg" :loading="$store.getters.isLoading">Disconnect</v-btn>
        </v-card-text>
        <v-card-text v-if="event && !event.org">

            <v-radio-group v-model="mode">
              <v-radio
                value="CREATE"
                label="Create a new organization"
                />
              <v-text-field v-if="mode == 'CREATE'"
                v-model="name"
                label="Name of the organization"
                class="ml-8"
                />


              <v-radio
                value="LINK"
                label="Link with an existing organization"
                />
              <v-select v-if="mode == 'LINK' && orgs"
                v-model="selectedOrgId"
                :items="orgs"
                item-text="name"
                item-value="id"
                label="Select your organization"
                class="ml-8"
                />
                <v-alert v-if="mode == 'LINK' && (!orgs || !orgs.length)" type="info">No organization found. Please create a new organization instead.</v-alert>
              </v-radio-group>
            <p>
              <v-btn color="primary" :disabled="!isValid" @click="linkWithOrg" :loading="$store.getters.isLoading">{{$t('shared.confirm')}}</v-btn>
            </p>
            <br/>
        </v-card-text>
        <div v-else>
            <v-skeleton-loader v-if="!user || $store.getters.loading" type="table"></v-skeleton-loader>
        </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "LinkOrg",
  components: {
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      mode: null,
      orgs: null,
      name: null,
      selectedOrgId: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        this.event = (await eventManagerService.get(this.$route.params.id)).data;
        this.orgs = (await orgManagerService.getOwnedEvents()).data.data;
      }
    },

    async unlinkFromOrg() {
      if (confirm('Are you sure you want to unlink this event from its organization.')) {
        const linkResponse = await eventManagerService.unlinkFromOrg(this.event.id);
        this.$helpers.toastResponse(this, linkResponse.data, 'Event is unlinked from its organization.');
        if (linkResponse.data.status === 'OK') {
          this.$router.push({name: 'eventmanagerView', params: {id:this.event.id }});
        }
      }
    },

    async linkWithOrg() {
      console.log('link with org', this.name, this.selectedOrgId);
      var linkResponse = null;
      if (this.mode === 'CREATE') {
          var response = await orgManagerService.create({
            name: this.name,
          });
          this.$analytics.event('create_org');
          linkResponse = await eventManagerService.linkOrg(this.event.id, response.data.id);
          //this.$router.push({ name: 'orgmanagerView', params: { id: response.data.id } });
          //return;
      }
      else if (this.mode === 'LINK') {
        linkResponse = await eventManagerService.linkOrg(this.event.id, this.selectedOrgId);
      }
      if (linkResponse) {
        this.$helpers.toastResponse(this, linkResponse.data, 'Event is linked with the selected organization.');
        if (linkResponse.data.status === 'OK') {
          this.$router.push({name: 'eventmanagerView', params: {id:this.event.id }});
        }
      }
    },
  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Create or link organization', disabled: true },
      ];
    },
    isValid() {
      return (this.mode == 'CREATE' && !!this.name) || (this.mode == 'LINK' && this.selectedOrgId);
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

